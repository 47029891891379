import { graphql, Link } from "gatsby"
import * as React from "react"
import { DefaultBottom } from "../components/banner/DefaultBottom"
import { HTMLContent } from "../components/common/Content"
import Layout from "../components/layout/Layout"
import Resources from "../components/projects/Resources"

// props
interface Props {
  data: {
    page: {
      html: any
      frontmatter: {
        title: string
        description?: string
        keywords?: Array<string>
        resources: Array<{
          title: string
          subtitle?: string
          description: string
          url: string
          image: string
        }>
        features: Array<{
          title: string
          description: string
        }>
        tiles: Array<{
          href?: string
          link?: string
          title: string
          description: string
        }>
      }
    }
  }
}

// markup
const BatchTemplate = (props: Props) => {
  const resources = props.data.page.frontmatter.resources
  const features = props.data.page.frontmatter.features
  const tiles = props.data.page.frontmatter.tiles
  return (
    <Layout
      seo={{
        title: props.data.page.frontmatter.title,
        description: props.data.page.frontmatter.description,
        keywords: props.data.page.frontmatter.keywords,
      }}
      className={`batch`}
    >
      <div className="extra-0">
        <img src={`/img/extra/reactive-6.svg`} alt="" />
      </div>
      <div className="container">
        <div className="hero-batch content py-6">
          <HTMLContent
            content={props.data.page.html}
            className={"markdown"}
          ></HTMLContent>
          <img className="image img-light" src={`/img/extra/batch-2.svg`} alt="" />
          <img className="image img-dark" src={`/img/extra/batch-2-dark.svg`} alt="" />
        </div>

        <div className="markdown content">
          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 has-text-weight-medium m-0 p-0">
                {features[0].title}
              </h1>
            </div>
            <div className="column is-8">
              <HTMLContent content={features[0].description}></HTMLContent>
              <Link
                className="button is-spring mt-5"
                to={`/guides/gs/batch-processing/`}
              >
                TAKE THE TUTORIAL
              </Link>
              <div className="pt-5 mt-3">
                <img
                  className="img-light"
                  src={`/img/extra/diagram-batch.svg`}
                  alt=""
                />
                <img
                  className="img-dark"
                  src={`/img/extra/diagram-batch-dark.svg`}
                  alt=""
                />
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 has-text-weight-medium m-0 p-0">
                {features[1].title}
              </h1>
            </div>
            <div className="column links-animate is-8">
              <div className="columns is-mobile">
                <div className="column is-8">
                  <HTMLContent
                    className=""
                    content={features[1].description}
                  ></HTMLContent>
                  <div className="mt-4 mr-6">
                    <Link to={`https://www.youtube.com/watch?v=t1Kwx8bXv4Y`}>
                      Why move batch to the cloud?
                    </Link>
                  </div>
                </div>
                <div className="column is-4">
                  <div className="img">
                    <img
                      className="img-light"
                      src={`/img/extra/batch-1.svg`}
                      style={{ maxWidth: 150 }}
                      alt=""
                    />
                    <img
                      className="img-dark"
                      src={`/img/extra/batch-1-dark.svg`}
                      style={{ maxWidth: 150 }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 has-text-weight-medium m-0 p-0">
                {features[2].title}
              </h1>
            </div>
            <div className="column links-animate is-8">
              <HTMLContent content={features[2].description}></HTMLContent>
              <div className="mt-4">
                <Link
                  to={`https://docs.spring.io/spring-batch/docs/current/reference/html/index.html`}
                >
                  Check out the docs
                </Link>
              </div>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6">
            <div className="column is-4 pr-6">
              <h1 className="is-size-2 has-text-weight-medium m-0 p-0">
                {features[3].title}
              </h1>
            </div>
            <div className="column links-animate is-6">
              <HTMLContent content={features[3].description}></HTMLContent>
            </div>
          </article>

          <article className="columns has-big-border-dark-top py-6 is-0">
            <div className="column is-4" />
            <div className="column">
              <div className="image">
                <img
                  className="img-light"
                  src={`/img/extra/batch-config-diagram.svg`}
                  alt=""
                />
                <img
                  className="img-dark"
                  src={`/img/extra/batch-config-diagram-dark.svg`}
                  alt=""
                />
              </div>
              <div className="columns links-animate tiles pt-6">
                <div className="column pr-6">
                  <div className="tile pt-3 pb-5">
                    <h3 className="title is-size-6 mb-2">{tiles[0].title}</h3>
                    <div className="description">{tiles[0].description}</div>
                    <div>
                      <a href={tiles[0].href}>{tiles[0].link}</a>
                    </div>
                  </div>
                  <div className="tile pt-3">
                    <h3 className="title is-size-6 mb-2">{tiles[1].title}</h3>
                    <div className="description">{tiles[1].description}</div>
                    <div>
                      <a href={tiles[1].href}>{tiles[1].link}</a>
                    </div>
                  </div>
                </div>
                <div className="column pr-6">
                  <div className="tile pt-3 pb-5">
                    <h3 className="title is-size-6 mb-2">{tiles[2].title}</h3>
                    <div className="description">{tiles[2].description}</div>
                  </div>
                  <div className="tile pt-3 pb-5">
                    <h3 className="title is-size-6 mb-2">{tiles[3].title}</h3>
                    <div className="description">{tiles[3].description}</div>
                    <div>
                      <a href={tiles[3].href}>{tiles[3].link}</a>
                    </div>
                  </div>
                  <div className="tile pt-3">
                    <h3 className="title is-size-6 mb-2">{tiles[4].title}</h3>
                    <div className="description">{tiles[4].description}</div>
                    <div>
                      <a href={tiles[4].href}>{tiles[4].link}</a>
                    </div>
                  </div>
                </div>
                <div className="column pr-6">
                  <div className="tile pt-3 pb-5">
                    <h3 className="title is-size-6 mb-2">{tiles[5].title}</h3>
                    <div className="description">{tiles[5].description}</div>
                    <div>
                      <a href={tiles[5].href}>{tiles[5].link}</a>
                    </div>
                  </div>
                  <div className="tile pt-3">
                    <h3 className="title is-size-6 mb-2">{tiles[6].title}</h3>
                    <div className="description">{tiles[6].description}</div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>

        <div className="has-background-light mt-6 p-6 has-text-centered">
          <div className="py-5">
            <h2 className="is-size-2 has-text-weight-medium pb-6">
              Ready to get started?
            </h2>
            <Link className="button is-spring" to={`/quickstart`}>
              TRY THIS TUTORIAL
            </Link>
          </div>
        </div>
        <Resources resources={resources} />
      </div>
      <DefaultBottom />
    </Layout>
  )
}

export default BatchTemplate

// graphQL queries
export const pageQuery = graphql`
  query BatchTemplate($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        keywords
        defaultClass
        resources {
          title
          subtitle
          description
          image
          url
          video
        }
        features {
          title
          description
        }
        tiles {
          href
          link
          title
          description
        }
      }
    }
  }
`
